import { useState } from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'

import { useApolloClient } from '@apollo/client'
import * as gql from './graphql'

import useWebSocket from 'react-use-websocket'

import { AuthProvider, RequireAuth } from './auth'
import Menu from './menu'
import Notifications from './notification'
import Receivers from './receivers'
import { useAppSelector, useAppDispatch } from './store'

import './App.css'

function AuthenticatedApp() {
  const apolloClient = useApolloClient()
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
  const endpoint = process.env.REACT_APP_HOSTNAME || window.location.host
  const url = `${protocol}//${endpoint}/api/ws/events`
  const socket = useWebSocket(url, {
    share: true,
    retryOnError: true,
    reconnectAttempts: 1000000,
    reconnectInterval: 1000,
    shouldReconnect: (ev) => true,
    onMessage: async (ev) => {
      const message = JSON.parse(ev.data)

      if (message.ev === 'update') {
        console.log('update event', message)
        if (message.receiver) {
          await apolloClient.query({ query: gql.GET_RECEIVER, variables: { receiverId: message.receiver } })
        }
      } else {
        console.log('unknown event', message)
      }
    },
  })

  return (
    <div>
      <div className="hidden lg:flex lg:w-16 lg:flex-col lg:fixed lg:inset-y-0">
        <Menu />
      </div>
      <main className="lg:ml-16">
        <Outlet />
      </main>
    </div>
  )
}

function AppRouter() {
  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <AuthenticatedApp />
            </RequireAuth>
          }>
          <Route index element={<Receivers />} />
        </Route>
      </Routes>
      <Notifications />
    </AuthProvider>
  )
}

export default AppRouter
