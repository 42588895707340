import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client'
import { resetGlobalState } from 'react-use-websocket'

import './index.css'
import App from './App'
import store from './store'
import reportWebVitals from './reportWebVitals'

const apolloEndpoint = process.env.REACT_APP_HOSTNAME || window.location.host
const apolloCache = new InMemoryCache({
  typePolicies: {},
})
const apolloLink = createHttpLink({
  uri: '/api/graphql',
  credentials: 'include',
})
const apolloClient = new ApolloClient({
  link: apolloLink,
  cache: apolloCache,
  name: 'ecqua-monitor-frontend',
  version: '1.0',
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
    },
  },
})

resetGlobalState()

const rootEl = document.getElementById('root')

if (rootEl) {
  ReactDOM.createRoot(rootEl).render(
    <React.StrictMode>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <HashRouter>
            <App />
          </HashRouter>
        </ApolloProvider>
      </Provider>
    </React.StrictMode>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
