import { Fragment } from 'react'

import { Transition } from '@headlessui/react'

import { CheckCircleIcon, ExclamationCircleIcon, ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'

import { useAppSelector, useAppDispatch } from '../store'
import { dismiss } from './slice'

export default function Notifications() {
  const dispatch = useAppDispatch()
  const items = useAppSelector((state) => state.notification.items)

  return (
    <div aria-live="assertive" className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={items.length > 0}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="max-w-sm md:max-w-lg w-full space-y-2">
            {items.map((item, i) => {
              const messages = item.message ? item.message.split(/\r\n|\n/) : []

              return (
                <div
                  key={i}
                  className="max-w-sm md:max-w-lg w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        {item.type === 'error' && <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />}
                        {item.type === 'info' && <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />}
                        {item.type === 'progress' && <ArrowPathIcon className="h-6 w-6 text-gray-400 animate-spin" aria-hidden="true" />}
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-gray-900">{item.title}</p>
                        {messages.length === 1 && <p className="mt-1 text-sm text-gray-500">{messages[0]}</p>}
                        {messages.length > 1 && (
                          <p className="mt-1 max-h-32 text-sm text-gray-500 overflow-auto whitespace-pre">
                            {messages.map((line, i) => (
                              <span key={i} className="block">
                                {line}
                              </span>
                            ))}
                          </p>
                        )}
                      </div>
                      <div className="ml-4 flex-shrink-0 flex">
                        {item.type !== 'progress' && (
                          <button
                            type="button"
                            className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => dispatch(dismiss(item))}>
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </Transition>
      </div>
    </div>
  )
}
