import { createSlice } from '@reduxjs/toolkit'

interface IShellState {
  status: { [k: string]: string }
  history: { [k: string]: string[] }
  commands: { [k: string]: string }
}

export const shellSlice = createSlice({
  name: 'shell',
  initialState: {
    status: {},
    history: {},
    commands: {},
  } as IShellState,
  reducers: {
    setStatus: (state, action) => {
      const { receiverId, value } = action.payload
      const status = { ...state.status }

      if (status[receiverId] !== 'error' || value !== 'disconnected') {
        status[receiverId] = value
      }
      return { ...state, status: status }
    },
    addMessage: (state, action) => {
      const { receiverId, message } = action.payload
      const history = { ...state.history }
      const messages = history[receiverId] || []

      history[receiverId] = messages.concat(message)
      return { ...state, history: history }
    },
    clearMessages: (state, action) => {
      const { receiverId } = action.payload
      const history = { ...state.history }

      history[receiverId] = []
      return { ...state, history: history }
    },
    setCommand: (state, action) => {
      const { receiverId, value } = action.payload
      const commands = { ...state.commands }

      commands[receiverId] = value
      return { ...state, commands: commands }
    },
    clearCommand: (state, action) => {
      const { receiverId } = action.payload
      const commands = { ...state.commands }

      delete commands[receiverId]
      return { ...state, commands: commands }
    },
  },
})
export const { setStatus, addMessage, clearMessages, setCommand, clearCommand } = shellSlice.actions
export default shellSlice.reducer
