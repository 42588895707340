import * as _ from 'lodash'
import classNames from 'classnames'

import { useState, Fragment, MouseEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DateTime, Duration } from 'luxon'
import DatePicker from 'react-date-picker'

import { Dialog, Menu, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ArrowUpTrayIcon,
  ArrowUturnLeftIcon,
  CommandLineIcon,
  EllipsisHorizontalIcon,
  MapIcon,
  MapPinIcon,
  PencilIcon,
  PlusIcon,
  RssIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'

import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import * as gql from '../graphql'

import { useFileUpload, FileUpload } from 'use-file-upload'

import { useAppDispatch } from '../store'
import { notify } from '../notification/slice'

import Shell from '../shell'

function makePollLocationSchedule(enabled: boolean = false, hour: number | null = 0, minute: number | null = 0, second: number | null = 0) {
  return {
    enabled,
    pollLocation: true,
    pollStatus: false,
    pollLogs: false,
    hour,
    minute,
    second,
  }
}

function makePollStatusSchedule(enabled: boolean = false, hour: number | null = 0, minute: number | null = 0, second: number | null = 0) {
  return {
    enabled,
    pollLocation: false,
    pollStatus: true,
    pollLogs: false,
    hour,
    minute,
    second,
  }
}

function makePollLogsSchedule(enabled: boolean = false, hour: number | null = 0, minute: number | null = 0, second: number | null = 0) {
  return {
    enabled,
    pollLocation: false,
    pollStatus: false,
    pollLogs: true,
    hour,
    minute,
    second,
  }
}

const POLL_LOCATION_SCHEDULES: { [k: string]: gql.IReceiverSchedule[] } = {
  never: [makePollLocationSchedule()],
  'every minute': Array.from(new Array(60).keys()).map((i) => makePollLocationSchedule(true, null, i)),
  'every 5 minutes': Array.from(new Array(12).keys()).map((i) => makePollLocationSchedule(true, null, i * 5)),
  'every 15 minutes': Array.from(new Array(4).keys()).map((i) => makePollLocationSchedule(true, null, i * 15)),
  'every 30 minutes': [makePollLocationSchedule(true, null, 0), makePollLocationSchedule(true, null, 30)],
  'every hour': [makePollLocationSchedule(true, null)],
  'every 2 hours': Array.from(new Array(12).keys()).map((i) => makePollLocationSchedule(true, i * 2)),
  'every 4 hours': Array.from(new Array(6).keys()).map((i) => makePollLocationSchedule(true, i * 4)),
  'every 8 hours': Array.from(new Array(3).keys()).map((i) => makePollLocationSchedule(true, i * 8)),
  'every 12 hours': Array.from(new Array(2).keys()).map((i) => makePollLocationSchedule(true, i * 12)),
  'every day': [makePollLocationSchedule(true)],
}

const POLL_STATUS_SCHEDULES: { [k: string]: gql.IReceiverSchedule[] } = {
  never: [makePollStatusSchedule()],
  // 'every minute': [makePollStatusSchedule(true, null, null)],
  'every hour': [makePollStatusSchedule(true, null)],
  'every 2 hours': Array.from(new Array(12).keys()).map((i) => makePollStatusSchedule(true, i * 2)),
  'every 4 hours': Array.from(new Array(6).keys()).map((i) => makePollStatusSchedule(true, i * 4)),
  'every 8 hours': Array.from(new Array(3).keys()).map((i) => makePollStatusSchedule(true, i * 8)),
  'every 12 hours': Array.from(new Array(2).keys()).map((i) => makePollStatusSchedule(true, i * 12)),
  'every day': [makePollStatusSchedule(true)],
}

const POLL_LOGS_SCHEDULES: { [k: string]: gql.IReceiverSchedule[] } = {
  never: [makePollLogsSchedule()],
  // 'every minute': [makePollLogsSchedule(true, null, null)],
  'every hour': [makePollLogsSchedule(true, null)],
  'at 8,9,10,...,18h': Array.from(new Array(11).keys()).map((i) => makePollLogsSchedule(true, i + 8)),
  'at 8,10,12,14,16,18h': Array.from(new Array(6).keys()).map((i) => makePollLogsSchedule(true, i * 2 + 8)),
  'at 8,12,16h': Array.from(new Array(3).keys()).map((i) => makePollLogsSchedule(true, i * 4 + 8)),
  'every 2 hours': Array.from(new Array(12).keys()).map((i) => makePollLogsSchedule(true, i * 2)),
  'every 3 hours': Array.from(new Array(8).keys()).map((i) => makePollLogsSchedule(true, i * 3)),
  'every 4 hours': Array.from(new Array(6).keys()).map((i) => makePollLogsSchedule(true, i * 4)),
  'every 6 hours': Array.from(new Array(4).keys()).map((i) => makePollLogsSchedule(true, i * 6)),
  'every 8 hours': Array.from(new Array(3).keys()).map((i) => makePollLogsSchedule(true, i * 8)),
  'every 12 hours': Array.from(new Array(2).keys()).map((i) => makePollLogsSchedule(true, i * 12)),
  'every day': [makePollLogsSchedule(true)],
}

interface SyncParams {
  from: DateTime | null
  to: DateTime | null
  pollLocation: boolean
  pollStatus: boolean
  pollLogs: boolean
}

interface ImportResult {
  filename: string
  id?: number
  logs?: number
  statuses?: number
  ignored?: number
  errors?: string[]
}

interface ImportResults {
  success: boolean
  error?: string
  results?: ImportResult[]
}

export default function Receivers() {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentTab = searchParams.get('tab') || 'active'
  const { data } = useQuery<gql.IListReceivers>(gql.LIST_RECEIVERS, {
    variables: {},
  })

  const apolloClient = useApolloClient()
  const [editedReceiver, setEditedReceiver] = useState<Partial<gql.IReceiver> | null>(null)
  const [activeConsole, setActiveConsole] = useState<gql.IReceiver | null>(null)
  const [activeSync, setActiveSync] = useState<gql.IReceiver[]>([])
  const [activeSyncSelection, setActiveSyncSelection] = useState<{ [k: string]: boolean }>({})
  const [activeSyncParams, setActiveSyncParams] = useState<Partial<SyncParams>>({})
  const [activeImport, setActiveImport] = useState<gql.IReceiver | null>(null)
  const [importBusy, setImportBusy] = useState<boolean>(false)
  const [importResults, setImportResults] = useState<ImportResults | null>(null)
  const [createReceiverMutation, createReceiverResponse] = useMutation(gql.CREATE_RECEIVER)
  const [updateReceiverMutation, updateReceiverResponse] = useMutation(gql.UPDATE_RECEIVER)
  const [deleteReceiverMutation, deleteReceiverResponse] = useMutation(gql.DELETE_RECEIVER)
  const [restoreReceiverMutation, restoreReceiverResponse] = useMutation(gql.RESTORE_RECEIVER)

  async function upsertReceiver(receiver: Partial<gql.IReceiver>, allowCreate: boolean = false) {
    const fields = {
      hostname: receiver.hostname,
      port: receiver.port,
      timeout: receiver.timeout,
      debug: receiver.debug,
      enabled: receiver.enabled,
      name: receiver.name,
      location: receiver.location,
      startPollingAt: receiver.startPollingAt,
      endPollingAt: receiver.endPollingAt,
      schedules: (receiver.schedules || []).map((schedule) => {
        return {
          enabled: schedule.enabled,
          pollLocation: schedule.pollLocation,
          pollStatus: schedule.pollStatus,
          pollLogs: schedule.pollLogs,
          hour: schedule.hour,
          minute: schedule.minute,
          second: schedule.second,
        }
      }),
    }

    setEditedReceiver(receiver)
    if (!!receiver.id) {
      await updateReceiverMutation({
        variables: {
          receiverId: receiver.id,
          ...fields,
        },
        onCompleted: (data) => {
          setEditedReceiver(data.updateReceiver)
          setSearchParams({ ...searchParams, tab: data.updateReceiver.enabled ? 'active' : 'inactive' })
        },
        // onError: (e) => dispatch(notify({ task: `upsert`, type: "error", title: 'Failed to update receiver', message: e.message })),
      })
    } else if (allowCreate) {
      await createReceiverMutation({
        variables: {
          ...fields,
        },
        onCompleted: (data) => {
          setEditedReceiver(null)
          setSearchParams({ ...searchParams, tab: data.createReceiver.enabled ? 'active' : 'inactive' })
        },
        // onError: (e) => dispatch(notify({ task: `upsert`, type: "error", title: 'Failed to create receiver', message: e.message })),
      })
      await apolloClient.refetchQueries({ include: [gql.LIST_RECEIVERS] })
    }
  }

  function deleteReceiver(receiver: gql.IReceiver) {
    deleteReceiverMutation({
      variables: {
        receiverId: receiver.id,
      },
      onCompleted: (data) => setEditedReceiver(null),
      onError: (e) => dispatch(notify({ task: `delete`, type: 'error', title: 'Failed to remove receiver', message: e.message })),
    })
  }

  function restoreReceiver(receiver: gql.IReceiver) {
    restoreReceiverMutation({
      variables: {
        receiverId: receiver.id,
      },
      onCompleted: (data) => setEditedReceiver(null),
      onError: (e) => dispatch(notify({ task: `restore`, type: 'error', title: 'Failed to restore receiver', message: e.message })),
    })
  }

  async function onPollReceiver(receiver: gql.IReceiver, path: string, message: string) {
    dispatch(notify({ task: `sync-${receiver.id}`, type: 'progress', title: `Syncing receiver ${receiver.name}`, message }))

    const response = await fetch(`/api/poll/${receiver.id}/${path}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
    })
    const json = await response.json()

    if (!response.ok) {
      dispatch(notify({ task: `sync-${receiver.id}`, type: 'error', title: `Failed to sync receiver ${receiver.name} (${path})`, message: json.error }))
      return false
    }
    return true
  }

  async function synchronizeReceiver(receiver: gql.IReceiver, params: SyncParams) {
    try {
      if (params.pollLocation && !(await onPollReceiver(receiver, 'location', 'SY+LO'))) {
        return
      }

      let current = (params.from || DateTime.now()).startOf('day')
      let end = (params.to || DateTime.now()).startOf('day')

      while (current.diff(end).valueOf() <= 0 && current.diffNow().valueOf() <= 0) {
        if (params.pollStatus && !(await onPollReceiver(receiver, `status/${current.toISODate()}`, `ER ${current.toISODate()}`))) {
          return
        }
        if (params.pollLogs && !(await onPollReceiver(receiver, `logs/${current.toISODate()}`, `UP ${current.toISODate()}`))) {
          return
        }
        current = current.plus({ days: 1 })
      }
      dispatch(notify({ task: `sync-${receiver.id}`, type: 'info', title: `Receiver ${receiver.name} synced` }))
    } catch (e) {
      console.error(e)
      dispatch(notify({ task: `sync-${receiver.id}`, type: 'error', title: `Failed to sync receiver ${receiver.name}`, message: (e as any).toString() }))
    }
  }

  async function synchronizeReceivers() {
    const selection = activeSync.filter((receiver) => !!activeSyncSelection[receiver.id])
    const params = activeSyncParams

    setActiveSync([])
    for (const receiver of selection) {
      const defaultParams = {
        from: receiver.startPollingAt ? DateTime.fromISO(receiver.startPollingAt) : DateTime.now(),
        to: receiver.endPollingAt ? DateTime.fromISO(receiver.endPollingAt) : DateTime.now(),
        pollLocation: receiver.schedules.some((schedule) => schedule.enabled && schedule.pollLocation),
        pollStatus: receiver.schedules.some((schedule) => schedule.enabled && schedule.pollStatus),
        pollLogs: receiver.schedules.some((schedule) => schedule.enabled && schedule.pollLogs),
      }

      await synchronizeReceiver(receiver, { ...defaultParams, ...params })
    }
  }

  async function onSynchronizeReceiver(ev: MouseEvent, receiver: gql.IReceiver) {
    ev.stopPropagation()

    setActiveSyncParams({
      from: receiver.startPollingAt ? DateTime.fromISO(receiver.startPollingAt) : DateTime.now(),
      to: receiver.endPollingAt ? DateTime.fromISO(receiver.endPollingAt) : DateTime.now(),
      pollLocation: receiver.schedules.some((schedule) => schedule.enabled && schedule.pollLocation),
      pollStatus: receiver.schedules.some((schedule) => schedule.enabled && schedule.pollStatus),
      pollLogs: receiver.schedules.some((schedule) => schedule.enabled && schedule.pollLogs),
    })
    setActiveSync([receiver])
    setActiveSyncSelection(_.fromPairs([[receiver.id, true]]))
  }

  async function onSynchronizeReceivers(ev: MouseEvent, receivers: gql.IReceiver[]) {
    ev.stopPropagation()

    setActiveSyncParams({})
    setActiveSync(receivers)
    setActiveSyncSelection(_.fromPairs(receivers.map((receiver) => [receiver.id, true])))
  }

  function onSwissTopoMap(ev: MouseEvent, receiver: gql.IReceiver) {
    // const phy = (receiver.latitude * 3600 - 169028.66) / 10000
    // const lambda = (receiver.longitude * 3600 - 26782.5) / 10000
    // const E = 2600072.37 + 211455.93 * lambda - 10938.51 * lambda * phy - 0.36 * lambda * phy * phy - 44.54 * lambda * lambda * lambda
    // const Y = E - 2000000.00
    // const N = 1200147.07 + 308807.95 * phy + 3745.25 * lambda * lambda + 76.63 * phy * phy - 194.56 * lambda * lambda * phy + 119.79 * phy * phy * phy
    // const X = N - 1000000.00

    // window.open(`https://map.geo.admin.ch/?X=${X}&Y=${Y}&zoom=8`, "_blank")
    window.open(`https://map.geo.admin.ch/?swisssearch=${receiver.lastLocalized.latitude},${receiver.lastLocalized.longitude}`, '_blank')
  }

  function onGoogleMap(ev: MouseEvent, receiver: gql.IReceiver) {
    window.open(`https://www.google.com/maps/place/${receiver.lastLocalized.latitude},${receiver.lastLocalized.longitude}`, '_blank')
  }

  function onCreateReceiver(ev: MouseEvent) {
    ev.preventDefault()
    ev.stopPropagation()

    createReceiverResponse.reset()
    setEditedReceiver({
      hostname: 'localhost',
      port: 20000,
      timeout: 10,
      enabled: false,
      name: 'New receiver',
      location: '',
      schedules: [],
    })
  }

  function onUpdateReceiver(ev: MouseEvent, receiver: gql.IReceiver) {
    ev.preventDefault()
    ev.stopPropagation()

    updateReceiverResponse.reset()
    setEditedReceiver(receiver)
  }

  function onDeleteReceiver(ev: MouseEvent, receiver: gql.IReceiver) {
    ev.preventDefault()
    ev.stopPropagation()

    deleteReceiverResponse.reset()
    deleteReceiver(receiver)
  }

  function onRestoreReceiver(ev: MouseEvent, receiver: gql.IReceiver) {
    ev.preventDefault()
    ev.stopPropagation()

    restoreReceiverResponse.reset()
    restoreReceiver(receiver)
  }

  const { receivers } = data || { receivers: [] }
  const activeReceivers = receivers.filter((receiver) => !receiver.deletedAt && receiver.enabled)
  const inactiveReceivers = receivers.filter((receiver) => !receiver.deletedAt && !receiver.enabled)
  const deletedReceivers = receivers.filter((receiver) => !!receiver.deletedAt)
  const deletedReceivers7days = deletedReceivers.filter((receiver) => DateTime.fromISO(receiver.deletedAt).diffNow().valueOf() / 1000 > -7 * 24 * 60 * 60)
  let currentReceivers = activeReceivers

  switch (currentTab) {
    case 'inactive':
      currentReceivers = inactiveReceivers
      break

    case 'deleted':
      currentReceivers = deletedReceivers //deletedReceivers7days
      break
  }

  function formatClock(value?: number | null) {
    return value && value < 10 ? `0${value}` : value
  }

  function formatSchedule(schedule: gql.IReceiverSchedule, withFlags: boolean = false) {
    let when = 'never'

    if (schedule.enabled) {
      if (schedule.hour !== null && schedule.minute !== null && schedule.second !== null) {
        if (schedule.second !== 0) {
          when = `at ${formatClock(schedule.hour)}:${formatClock(schedule.minute)}:${formatClock(schedule.second)}`
        } else if (schedule.minute !== 0) {
          when = `at ${formatClock(schedule.hour)}:${formatClock(schedule.minute)}`
        } else {
          when = `at ${formatClock(schedule.hour)}:00`
        }
      } else if (schedule.hour === null && schedule.minute !== null && schedule.second !== null) {
        when = 'every hour'
      } else if (schedule.hour === null && schedule.minute === null && schedule.second !== null) {
        when = 'every minute'
      } else if (schedule.hour === null && schedule.minute === null && schedule.second === null) {
        when = 'every second'
      } else {
        throw Error(`Invalid schedule: ${JSON.stringify(schedule)}`)
      }
    }
    if (withFlags) {
      const flags = []

      if (schedule.pollLocation) {
        flags.push('location')
      }
      if (schedule.pollStatus) {
        flags.push('status')
      }
      if (schedule.pollLogs) {
        flags.push('logs')
      }
      return flags.join('/') + ': ' + when
    }
    return when
  }

  function formatSchedules(schedules: gql.IReceiverSchedule[], withFlags: boolean = false) {
    const sorted = [...schedules].sort((a, b) => {
      return formatSchedule(a, true).localeCompare(formatSchedule(b, true))
    })

    return sorted.map((schedule, i) => formatSchedule(schedule, withFlags && i === 0)).join(', ')
  }

  function pollLocationSchedule(receiver: Partial<gql.IReceiver>) {
    const value = formatSchedules((receiver.schedules || []).filter((schedule) => schedule.pollLocation))

    return Object.keys(POLL_LOCATION_SCHEDULES).find((key) => value === formatSchedules(POLL_LOCATION_SCHEDULES[key])) || 'never'
  }

  function pollStatusSchedule(receiver: Partial<gql.IReceiver>) {
    const value = formatSchedules((receiver.schedules || []).filter((schedule) => schedule.pollStatus))

    return Object.keys(POLL_STATUS_SCHEDULES).find((key) => value === formatSchedules(POLL_STATUS_SCHEDULES[key])) || 'never'
  }

  function pollLogsSchedule(receiver: Partial<gql.IReceiver>) {
    const value = formatSchedules((receiver.schedules || []).filter((schedule) => schedule.pollLogs))

    return Object.keys(POLL_LOGS_SCHEDULES).find((key) => value === formatSchedules(POLL_LOGS_SCHEDULES[key])) || 'never'
  }

  function withPollLocationSchedule(receiver: Partial<gql.IReceiver> | null, value: string) {
    const schedules = POLL_LOCATION_SCHEDULES[value]

    return ((receiver && receiver.schedules) || []).filter((other) => !other.pollLocation).concat(schedules)
  }

  function withPollStatusSchedule(receiver: Partial<gql.IReceiver> | null, value: string) {
    const schedules = POLL_STATUS_SCHEDULES[value]

    return ((receiver && receiver.schedules) || []).filter((other) => !other.pollStatus).concat(schedules)
  }

  function withPollLogsSchedule(receiver: Partial<gql.IReceiver> | null, value: string) {
    const schedules = POLL_LOGS_SCHEDULES[value]

    return ((receiver && receiver.schedules) || []).filter((other) => !other.pollLogs).concat(schedules)
  }

  const [, selectFile] = useFileUpload()

  function onImportLogs(receiver: gql.IReceiver, accept: string = '*/*') {
    selectFile(
      {
        accept,
        multiple: true,
      },
      async (value) => {
        const files = value as FileUpload[]
        let results: ImportResults = {
          success: true,
          results: files.map((file) => {
            return {
              filename: file.file.name,
            }
          }),
        }

        setImportBusy(true)
        setImportResults(results)
        setActiveImport(receiver)
        try {
          for (const file of files) {
            const data = new FormData()

            data.append(`file`, file.file)

            const response = await fetch(`/api/parse/${receiver.id}`, {
              method: 'POST',
              body: data,
              mode: 'cors',
              cache: 'no-cache',
              credentials: 'include',
            })

            if (response.ok) {
              const json: ImportResults = await response.json()

              if (json.success) {
                results = {
                  ...results,
                  results: (results.results || []).map((result) => (json.results || []).find((other) => other.filename === result.filename) || result),
                }
                setImportResults(results)
              } else {
                dispatch(
                  notify({ task: `import-${receiver.id}`, type: 'error', title: `Failed to import logs for receiver ${receiver.name}`, message: json.error })
                )
              }
            } else {
              dispatch(
                notify({
                  task: `import-${receiver.id}`,
                  type: 'error',
                  title: `Failed to import logs for receiver ${receiver.name}`,
                  message: response.statusText,
                })
              )
            }
          }
        } finally {
          setImportBusy(false)
        }
      }
    )
  }

  function onExportEvents(receiver: gql.IReceiver) {
    window.open(`/api/export/${receiver.id}/events`, '_blank')
  }

  return (
    <div className="py-6">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Receivers</h1>
            <p className="mt-2 text-sm text-gray-700">List of configured receivers</p>
          </div>
          <div className="mt-4 space-x-2 sm:mt-0 sm:ml-16 sm:flex-none flex items-center">
            <div className="inline-flex basis-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={searchParams.get('tab') || 'active'}
                onChange={(ev) => setSearchParams({ ...searchParams, tab: ev.target.value })}>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                <option value="deleted">Deleted</option>
              </select>
            </div>
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:text-gray-200"
              title="Synchronize All Receivers"
              disabled={activeReceivers.length === 0 || activeReceivers.some((receiver) => receiver.busy)}
              onClick={(ev) => onSynchronizeReceivers(ev, activeReceivers)}>
              <ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Synchronize All Receivers</span>
            </button>
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              title="Add New Receiver"
              onClick={onCreateReceiver}>
              <PlusIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Add New Receiver</span>
            </button>
          </div>
        </div>
        <div className="mt-8">
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                <button
                  onClick={(ev) => setSearchParams({ ...searchParams, tab: 'active' })}
                  className={classNames(
                    (searchParams.get('tab') || 'active') === 'active'
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                  )}>
                  Active
                  {activeReceivers.length > 0 && (
                    <span className="inline-flex items-center px-2.5 py-0.5 ml-2 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                      {activeReceivers.length}
                    </span>
                  )}
                </button>
                <button
                  onClick={(ev) => setSearchParams({ ...searchParams, tab: 'inactive' })}
                  className={classNames(
                    searchParams.get('tab') === 'inactive'
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                  )}>
                  Inactive
                  {inactiveReceivers.length > 0 && (
                    <span className="inline-flex items-center px-2.5 py-0.5 ml-2 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                      {inactiveReceivers.length}
                    </span>
                  )}
                </button>
                <button
                  onClick={(ev) => setSearchParams({ ...searchParams, tab: 'deleted' })}
                  className={classNames(
                    searchParams.get('tab') === 'deleted'
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                  )}>
                  Deleted
                  {deletedReceivers.length > 0 && (
                    <span className="inline-flex items-center px-2.5 py-0.5 ml-2 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                      {deletedReceivers.length}
                    </span>
                  )}
                </button>
              </nav>
            </div>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="w-32 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Name
                      </th>
                      {currentTab !== 'deleted' && <th scope="col" className="hidden sm:table-cell w-64 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Address
                      </th>}
                      <th scope="col" className="w-36 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Last polled
                      </th>
                      <th scope="col" className="w-48 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        GPS
                      </th>
                      <th scope="col" className="hidden sm:table-cell w-36 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Status
                      </th>
                      <th scope="col" className="hidden sm:table-cell w-48 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Antennas
                      </th>
                      <th scope="col" className="hidden sm:table-cell w-40 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Schedules
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {currentReceivers.length === 0 ? (
                      <tr>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500" colSpan={currentTab !== 'deleted' ? 8 : 7}>
                          {currentTab === 'deleted' && deletedReceivers.length - deletedReceivers7days.length > 0
                            ? `No deleted receiver recently, but ${deletedReceivers.length - deletedReceivers7days.length} more are in database.`
                            : `No ${currentTab} receiver.`}
                        </td>
                      </tr>
                    ) : (
                      ''
                    )}
                    {currentReceivers.map((receiver, i) => (
                      <tr
                        key={receiver.id}
                        className={classNames("align-top", i % 2 === 0 ? undefined : 'bg-gray-50')}
                        onClick={(ev) => /*receiver.enabled && onOpenReceiver(ev, receiver)*/ null}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div title={receiver.currentTask} className="font-bold">
                            {(receiver.enabled || receiver.opened) && !receiver.deletedAt && <RssIcon className={classNames("inline-block h-4 w-4 mr-1", { "text-green-500": receiver.opened && !receiver.busy, "text-purple-500": receiver.opened && receiver.busy, "text-red-500": receiver.enabled && !receiver.opened })} aria-hidden="true" />}
                            {receiver.name}
                          </div>
                          {receiver.location && <div className="hidden sm:block text-xs text-gray-500">{receiver.location}</div>}
                          {receiver.firmware && (
                            <div className="hidden sm:block text-xs text-gray-500" title={`${receiver.device || 'N/A'} (S/N: ${receiver.serial})`}>
                              {receiver.firmware}
                            </div>
                          )}
                        </td>
                        {currentTab !== 'deleted' && <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <a href={`http://${receiver.hostname}:81`} target="_blank" rel="noreferrer" title="Open modem administration">
                            {receiver.hostname}:{receiver.port}
                          </a>
                        </td>}
                        <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                          {!receiver.lastPolled && <div>-</div>}
                          {receiver.lastPolled && (
                            <div>
                              <div>{DateTime.fromISO(receiver.lastPolled.localizedAt).toLocaleString(DateTime.DATETIME_SHORT)}</div>
                              {receiver.lastPolled.localtime && <div className="hidden sm:block">
                                local time: {DateTime.fromISO(receiver.lastPolled.localtime).toLocaleString(DateTime.DATETIME_SHORT)}
                              </div>}
                            </div>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                          {!receiver.lastLocalized && <div>-</div>}
                          {receiver.lastLocalized && (
                            <div>
                              <button
                                title="Swisstopo Map"
                                className="text-gray-500 hover:text-gray-700 space-x-1 "
                                onClick={(ev) => onSwissTopoMap(ev, receiver)}>
                                <span>{receiver.lastLocalized.latitude.toFixed(3)}°N</span>
                                <span>{receiver.lastLocalized.longitude.toFixed(3)}°E</span>
                              </button>
                              {receiver.lastLocalized.altitude > 0 && <div>altitude: {receiver.lastLocalized.altitude}[m]</div>}
                              <div className="hidden sm:block">
                                last signal: {DateTime.fromISO(receiver.lastLocalized.localizedAt).toLocaleString(DateTime.DATETIME_SHORT)}
                              </div>
                              {receiver.lastLocalized.timeToValidGnssSignal > 0 && (
                                <div className="hidden sm:block">
                                  time to gnss: {Duration.fromMillis(receiver.lastLocalized.timeToValidGnssSignal * 1000).toFormat('mm:ss')}
                                </div>
                              )}
                            </div>
                          )}
                        </td>
                        <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                          {!receiver.lastPolled && <div>-</div>}
                          {receiver.lastPolled && (
                            <div>
                              {receiver.lastPolled.state && <div>mode: {receiver.lastPolled.state}</div>}
                              {receiver.lastPolled.beeper !== null && <div>beeper: {receiver.lastPolled.beeper ? 'yes' : 'no'}</div>}
                              {receiver.lastPolled.bluetooth !== null && <div>bluetooth: {receiver.lastPolled.bluetooth ? 'yes' : 'no'}</div>}
                              {receiver.lastPolled.v && <div>supply: {receiver.lastPolled.v.toFixed(1)}[V]</div>}
                              {receiver.lastPolled.shutdownSupercap && <div>supercap: {receiver.lastPolled.shutdownSupercap.toFixed(1)}[V]</div>}
                              {receiver.lastPolled.sleepBattery && <div>sleep battery: {receiver.lastPolled.sleepBattery.toFixed(1)}[V]</div>}
                            </div>
                          )}
                        </td>
                        <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                          {!receiver.lastPolled && <div>-</div>}
                          {receiver.lastPolled && (
                            <div>
                              {receiver.lastPolled.chargePulse1 && receiver.lastPolled.noise1 && (
                                <div>
                                  antenna 1: {receiver.lastPolled.chargePulse1.toFixed(2)}[A] (noise:{receiver.lastPolled.noise1})
                                </div>
                              )}
                              {receiver.lastPolled.chargePulse2 && receiver.lastPolled.noise2 && (
                                <div>
                                  antenna 2: {receiver.lastPolled.chargePulse2.toFixed(2)}[A] (noise:{receiver.lastPolled.noise2})
                                </div>
                              )}
                              {receiver.lastPolled.chargePulse3 && receiver.lastPolled.noise3 && (
                                <div>
                                  antenna 3: {receiver.lastPolled.chargePulse3.toFixed(2)}[A] (noise:{receiver.lastPolled.noise3})
                                </div>
                              )}
                              {receiver.lastPolled.chargePulse4 && receiver.lastPolled.noise4 && (
                                <div>
                                  antenna 4: {receiver.lastPolled.chargePulse4.toFixed(2)}[A] (noise:{receiver.lastPolled.noise4})
                                </div>
                              )}
                              {receiver.lastPolled.aL && <div>listen: {receiver.lastPolled.aL.toFixed(2)}[A]</div>}
                              {receiver.lastPolled.aE && <div>effective: {receiver.lastPolled.aE.toFixed(2)}[A]</div>}
                              {receiver.lastPolled.tagsInArchive && <div>tags: {receiver.lastPolled.tagsInArchive}</div>}
                            </div>
                          )}
                        </td>
                        <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                          {receiver.startPollingAt && receiver.endPollingAt && (
                            <div>
                              {`${DateTime.fromISO(receiver.startPollingAt).toLocaleString(DateTime.DATE_SHORT)} to ${DateTime.fromISO(
                                receiver.endPollingAt
                              ).toLocaleString(DateTime.DATE_SHORT)}`}
                            </div>
                          )}
                          {receiver.startPollingAt && !receiver.endPollingAt && (
                            <div>{`since ${DateTime.fromISO(receiver.startPollingAt).toLocaleString(DateTime.DATE_SHORT)}`}</div>
                          )}
                          {!receiver.startPollingAt && receiver.endPollingAt && (
                            <div>{`until ${DateTime.fromISO(receiver.endPollingAt).toLocaleString(DateTime.DATE_SHORT)}`}</div>
                          )}
                          {!receiver.startPollingAt && !receiver.endPollingAt && <div>any period</div>}
                          <div>SY+LO: {pollLocationSchedule(receiver)}</div>
                          <div>ER: {pollStatusSchedule(receiver)}</div>
                          <div>UP: {pollLogsSchedule(receiver)}</div>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 space-x-4 md:space-x-2 text-right text-sm font-medium sm:pr-6">
                          {!receiver.deletedAt && (
                            <button
                              className="text-indigo-600 hover:text-indigo-900 disabled:text-gray-200"
                              title="Edit"
                              onClick={(ev) => onUpdateReceiver(ev, receiver)}>
                              <PencilIcon className="h-8 w-8 md:h-5 md:w-5" aria-hidden="true" />
                              <span className="sr-only">Edit</span>
                            </button>
                          )}
                          {!!receiver.deletedAt && (
                            <button className="text-gray-400 hover:text-gray-600" title="Restore" onClick={(ev) => onRestoreReceiver(ev, receiver)}>
                              <ArrowUturnLeftIcon className="h-8 w-8 md:h-5 md:w-5" aria-hidden="true" />
                              <span className="sr-only">Restore</span>
                            </button>
                          )}
                          <Menu as="div" className="relative inline-block text-left">
                            <div>
                              <Menu.Button className="inline-flex justify-center text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                <EllipsisHorizontalIcon className="h-8 w-8 md:h-5 md:w-5" aria-hidden="true" />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95">
                              <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                {!receiver.deletedAt && (<div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        className={classNames(
                                          active ? 'bg-gray-100 text-gray-700' : 'text-gray-500',
                                          'group flex items-center w-full px-4 py-2 text-sm disabled:text-gray-200'
                                        )}
                                        title="Open Terminal"
                                        disabled={receiver.busy}
                                        onClick={(ev) => setActiveConsole(receiver)}>
                                        <CommandLineIcon className="mr-3 h-5 w-5" aria-hidden="true" />
                                        Open Terminal
                                      </button>
                                    )}
                                  </Menu.Item>
                                  {receiver.enabled && (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <button
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-700' : 'text-gray-500',
                                            'group flex items-center w-full px-4 py-2 text-sm disabled:text-gray-200'
                                          )}
                                          title="Synchronize"
                                          disabled={receiver.busy}
                                          onClick={(ev) => onSynchronizeReceiver(ev, receiver)}>
                                          <ArrowPathIcon className="mr-3 h-5 w-5" aria-hidden="true" />
                                          Synchronize
                                        </button>
                                      )}
                                    </Menu.Item>
                                  )}
                                </div>)}
                                {!receiver.deletedAt && receiver.lastLocalized && (
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <button
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-700' : 'text-gray-500',
                                            'group flex items-center w-full px-4 py-2 text-sm disabled:text-gray-200'
                                          )}
                                          title="Google Map"
                                          onClick={(ev) => onSwissTopoMap(ev, receiver)}>
                                          <MapIcon className="mr-3 h-5 w-5" aria-hidden="true" />
                                          Swisstopo Map
                                        </button>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <button
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-700' : 'text-gray-500',
                                            'group flex items-center w-full px-4 py-2 text-sm disabled:text-gray-200'
                                          )}
                                          title="Google Map"
                                          onClick={(ev) => onGoogleMap(ev, receiver)}>
                                          <MapPinIcon className="mr-3 h-5 w-5" aria-hidden="true" />
                                          Google Map
                                        </button>
                                      )}
                                    </Menu.Item>
                                  </div>
                                )}
                                <div className="py-1">
                                  {!receiver.deletedAt && (<Menu.Item>
                                    {({ active }) => (
                                      <button
                                        className={classNames(
                                          active ? 'bg-gray-100 text-gray-700' : 'text-gray-500',
                                          'group flex items-center w-full px-4 py-2 text-sm disabled:text-gray-200'
                                        )}
                                        title="Import Logs"
                                        onClick={(ev) => onImportLogs(receiver)}>
                                        <ArrowUpTrayIcon className="mr-3 h-5 w-5" aria-hidden="true" />
                                        Import Logs
                                      </button>
                                    )}
                                  </Menu.Item>)}
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        className={classNames(
                                          active ? 'bg-gray-100 text-gray-700' : 'text-gray-500',
                                          'group flex items-center w-full px-4 py-2 text-sm disabled:text-gray-200'
                                        )}
                                        title="Export Events"
                                        onClick={(ev) => onExportEvents(receiver)}>
                                        <ArrowDownTrayIcon className="mr-3 h-5 w-5" aria-hidden="true" />
                                        Export events
                                      </button>
                                    )}
                                  </Menu.Item>
                                </div>
                                {!receiver.deletedAt && (<div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        className={classNames(
                                          active ? 'bg-gray-100 text-red-700' : 'text-red-500',
                                          'group flex items-center w-full px-4 py-2 text-sm disabled:text-gray-200'
                                        )}
                                        title="Delete"
                                        disabled={receiver.busy}
                                        onClick={(ev) => onDeleteReceiver(ev, receiver)}>
                                        <TrashIcon className="mr-3 h-5 w-5" aria-hidden="true" />
                                        Delete
                                      </button>
                                    )}
                                  </Menu.Item>
                                </div>)}
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={!!editedReceiver} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setEditedReceiver(null)}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            {editedReceiver && !!editedReceiver.id ? 'Edit Receiver' : 'Add Receiver'}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setEditedReceiver(null)}>
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <form className="space-y-8 divide-y divide-gray-200">
                          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div className="pt-2 space-y-2">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">Network Address</h3>
                              <div>
                                <label htmlFor="hostname" className="block text-sm font-medium text-gray-700">
                                  Hostname or IP
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="hostname"
                                    id="hostname"
                                    disabled={!editedReceiver || (editedReceiver && !!editedReceiver.id)}
                                    value={(editedReceiver && editedReceiver.hostname) || ''}
                                    onChange={(ev) => upsertReceiver({ ...editedReceiver, hostname: ev.target.value })}
                                    autoComplete="hostname"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-100 block w-full sm:text-sm border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="port" className="block text-sm font-medium text-gray-700">
                                  Port number
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="number"
                                    name="port"
                                    id="port"
                                    disabled={!editedReceiver || (editedReceiver && !!editedReceiver.id)}
                                    value={(editedReceiver && editedReceiver.port) || ''}
                                    onChange={(ev) => upsertReceiver({ ...editedReceiver, port: parseInt(ev.target.value) })}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-100 block w-full sm:text-sm border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="timeout" className="block text-sm font-medium text-gray-700">
                                  Connect/Read Timeout (seconds)
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="number"
                                    name="timeout"
                                    id="timeout"
                                    disabled={!editedReceiver}
                                    value={(editedReceiver && editedReceiver.timeout) || ''}
                                    onChange={(ev) => upsertReceiver({ ...editedReceiver, timeout: parseInt(ev.target.value) })}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-100 block w-full sm:text-sm border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>
                              <fieldset>
                                <div className="mt-1 space-y-4">
                                  <div className="relative flex items-start">
                                    <div className="flex items-center h-5">
                                      <input
                                        id="debug"
                                        name="debug"
                                        type="checkbox"
                                        disabled={!editedReceiver}
                                        checked={(editedReceiver && editedReceiver.debug) || false}
                                        onChange={(ev) => upsertReceiver({ ...editedReceiver, debug: ev.target.checked })}
                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 disabled:text-gray-500 border-gray-300 rounded"
                                      />
                                    </div>
                                    <div className="ml-3 text-sm">
                                      <label htmlFor="debug" className="font-medium text-gray-700">
                                        Enable Debug
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                              <fieldset>
                                <div className="mt-1 space-y-4">
                                  <div className="relative flex items-start">
                                    <div className="flex items-center h-5">
                                      <input
                                        id="enabled"
                                        name="enabled"
                                        type="checkbox"
                                        disabled={!editedReceiver}
                                        checked={(editedReceiver && editedReceiver.enabled) || false}
                                        onChange={(ev) => upsertReceiver({ ...editedReceiver, enabled: ev.target.checked })}
                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 disabled:text-gray-500 border-gray-300 rounded"
                                      />
                                    </div>
                                    <div className="ml-3 text-sm">
                                      <label htmlFor="enabled" className="font-medium text-gray-700">
                                        Enable Receiver
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                            <div className="pt-2 space-y-2">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">Basic Information</h3>
                              <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                  Receiver Name
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    disabled={!editedReceiver}
                                    value={(editedReceiver && editedReceiver.name) || ''}
                                    onChange={(ev) => upsertReceiver({ ...editedReceiver, name: ev.target.value })}
                                    autoComplete="name"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-100 block w-full sm:text-sm border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                  Location
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="location"
                                    id="location"
                                    disabled={!editedReceiver}
                                    value={(editedReceiver && editedReceiver.location) || ''}
                                    onChange={(ev) => upsertReceiver({ ...editedReceiver, location: ev.target.value })}
                                    autoComplete="location"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-100 block w-full sm:text-sm border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="pt-2 space-y-2">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">Schedule</h3>
                              <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                  Starts On (recommended for ER / manual sync)
                                </label>
                                <div className="mt-1">
                                  <DatePicker
                                    className="form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    calendarIcon={null}
                                    disabled={!editedReceiver}
                                    value={editedReceiver && editedReceiver.startPollingAt ? DateTime.fromISO(editedReceiver.startPollingAt).toJSDate() : null}
                                    onChange={(value: Date | null) =>
                                      upsertReceiver({ ...editedReceiver, startPollingAt: value !== null ? DateTime.fromJSDate(value).toISO() : null })
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                  Poll Location (SY + LO)
                                </label>
                                <div className="mt-1">
                                  <select
                                    id="pollStatus"
                                    name="pollStatus"
                                    disabled={!editedReceiver}
                                    value={(editedReceiver && pollLocationSchedule(editedReceiver)) || ''}
                                    onChange={(ev) =>
                                      upsertReceiver({ ...editedReceiver, schedules: withPollLocationSchedule(editedReceiver, ev.target.value) })
                                    }
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-100 block w-full sm:text-sm border-gray-300 rounded-md">
                                    {Object.keys(POLL_LOCATION_SCHEDULES).map((key, i) => {
                                      return (
                                        <option key={i} value={key}>
                                          {key}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div>
                                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                  Poll Status (ER)
                                </label>
                                <div className="mt-1">
                                  <select
                                    id="pollStatus"
                                    name="pollStatus"
                                    disabled={!editedReceiver}
                                    value={(editedReceiver && pollStatusSchedule(editedReceiver)) || ''}
                                    onChange={(ev) => upsertReceiver({ ...editedReceiver, schedules: withPollStatusSchedule(editedReceiver, ev.target.value) })}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-100 block w-full sm:text-sm border-gray-300 rounded-md">
                                    {Object.keys(POLL_STATUS_SCHEDULES).map((key, i) => {
                                      return (
                                        <option key={i} value={key}>
                                          {key}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div>
                                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                  Poll Logs (UP)
                                </label>
                                <div className="mt-1">
                                  <select
                                    id="pollStatus"
                                    name="pollStatus"
                                    disabled={!editedReceiver}
                                    value={(editedReceiver && pollLogsSchedule(editedReceiver)) || ''}
                                    onChange={(ev) => upsertReceiver({ ...editedReceiver, schedules: withPollLogsSchedule(editedReceiver, ev.target.value) })}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-100 block w-full sm:text-sm border-gray-300 rounded-md">
                                    {Object.keys(POLL_LOGS_SCHEDULES).map((key, i) => {
                                      return (
                                        <option key={i} value={key}>
                                          {key}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                  Ends On (optional)
                                </label>
                                <div className="mt-1">
                                  <DatePicker
                                    className="form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    calendarIcon={null}
                                    disabled={!editedReceiver}
                                    value={editedReceiver && editedReceiver.endPollingAt ? DateTime.fromISO(editedReceiver.endPollingAt).toJSDate() : null}
                                    onChange={(value: Date | null) =>
                                      upsertReceiver({ ...editedReceiver, endPollingAt: value !== null ? DateTime.fromJSDate(value).toISO() : null })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            {editedReceiver && !!editedReceiver.id && (
                              <div className="pt-2 space-y-2">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Hardware Information</h3>
                                {editedReceiver.device && (
                                  <div>
                                    <label htmlFor="device" className="block text-sm font-medium text-gray-700">
                                      Model
                                    </label>
                                    <div className="mt-1">
                                      <input
                                        type="text"
                                        name="device"
                                        id="device"
                                        value={editedReceiver.device}
                                        disabled={true}
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-100 block w-full sm:text-sm border-gray-300 rounded-md"
                                      />
                                    </div>
                                  </div>
                                )}
                                {editedReceiver.serial && (
                                  <div>
                                    <label htmlFor="serial" className="block text-sm font-medium text-gray-700">
                                      Serial Number
                                    </label>
                                    <div className="mt-1">
                                      <input
                                        type="text"
                                        name="serial"
                                        id="serial"
                                        value={editedReceiver.serial}
                                        disabled={true}
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-100 block w-full sm:text-sm border-gray-300 rounded-md"
                                      />
                                    </div>
                                  </div>
                                )}
                                {editedReceiver.firmware && (
                                  <div>
                                    <label htmlFor="firmware" className="block text-sm font-medium text-gray-700">
                                      Firmware
                                    </label>
                                    <div className="mt-1">
                                      <input
                                        type="text"
                                        name="firmware"
                                        id="firmware"
                                        value={editedReceiver.firmware}
                                        disabled={true}
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-100 block w-full sm:text-sm border-gray-300 rounded-md"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          {editedReceiver && !!editedReceiver.id && (
                            <div className="pt-2 space-y-2">
                              {updateReceiverResponse.error && (
                                <div className="rounded-md bg-red-50 p-4">
                                  <div className="flex">
                                    <div className="flex-shrink-0">
                                      <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3">
                                      <h3 className="text-sm font-medium text-red-800">{updateReceiverResponse.error.message}</h3>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                          {editedReceiver && !editedReceiver.id && (
                            <div className="pt-2 space-y-2">
                              {createReceiverResponse.error && (
                                <div className="rounded-md bg-red-50 p-4">
                                  <div className="flex">
                                    <div className="flex-shrink-0">
                                      <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3">
                                      <h3 className="text-sm font-medium text-red-800">{createReceiverResponse.error.message}</h3>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                onClick={(ev) => upsertReceiver(editedReceiver, true)}>
                                Add receiver
                              </button>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={!!activeConsole} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={(ev) => setActiveConsole(null)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-4xl sm:w-full sm:p-6">
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      tabIndex={-1}
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => setActiveConsole(null)}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div>
                    <div className="mt-3 sm:mt-5">
                      <Dialog.Title as="h3" className="text-center text-lg leading-6 font-medium text-gray-900">
                        Terminal: {activeConsole && activeConsole.name}
                      </Dialog.Title>
                      <div className="mt-2">{activeConsole && <Shell receiverId={activeConsole.id} onClose={() => setActiveConsole(null)} />}</div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={activeSync.length > 0} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={(ev) => setActiveSync([])}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      tabIndex={-1}
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => setActiveSync([])}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  {activeSync.length > 0 && !!activeSyncParams && (
                    <div>
                      <div className="mt-3 sm:mt-5">
                        <Dialog.Title as="h3" className="text-center text-lg leading-6 font-medium text-gray-900">
                          Synchronize: {activeSync.length > 1 ? 'ALL' : activeSync[0].name}
                        </Dialog.Title>
                        <div className="mt-2">
                          {activeSync.length > 1 && (
                            <fieldset>
                              <div className="mt-4 space-y-2">
                                <label className="block text-sm font-medium text-gray-700">Selection</label>
                                {activeSync.map((receiver, i) => (
                                  <div key={i} className="relative flex items-start">
                                    <div className="flex items-center h-5">
                                      <input
                                        id={`selection-${receiver.id}`}
                                        type="checkbox"
                                        checked={activeSyncSelection[receiver.id] || false}
                                        onChange={(ev) =>
                                          setActiveSyncSelection({ ...activeSyncSelection, ..._.fromPairs([[receiver.id, ev.target.checked]]) })
                                        }
                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 disabled:text-gray-500 border-gray-300 rounded"
                                      />
                                    </div>
                                    <div className="ml-3 text-sm">
                                      <label htmlFor={`selection-${receiver.id}`} className="font-medium text-gray-700">
                                        {receiver.name}
                                      </label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </fieldset>
                          )}
                          <div className="pt-2 space-y-2">
                            <div>
                              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                From
                              </label>
                              <div className="mt-1">
                                <DatePicker
                                  className="form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                  calendarIcon={null}
                                  value={!!activeSyncParams.from ? activeSyncParams.from.toJSDate() : null}
                                  onChange={(value: Date | null) =>
                                    setActiveSyncParams({ ...activeSyncParams, from: value !== null ? DateTime.fromJSDate(value) : null })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="pt-2 space-y-2">
                            <div>
                              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                To
                              </label>
                              <div className="mt-1">
                                <DatePicker
                                  className="form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                  calendarIcon={null}
                                  value={!!activeSyncParams.to ? activeSyncParams.to.toJSDate() : null}
                                  onChange={(value: Date | null) =>
                                    setActiveSyncParams({ ...activeSyncParams, to: value !== null ? DateTime.fromJSDate(value) : null })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {(activeSyncParams.pollLocation !== undefined ||
                            activeSyncParams.pollStatus !== undefined ||
                            activeSyncParams.pollLogs !== undefined) && (
                              <fieldset>
                                <div className="mt-4 space-y-2">
                                  <label className="block text-sm font-medium text-gray-700">Poll</label>
                                  {activeSyncParams.pollLocation !== undefined && (
                                    <div className="relative flex items-start">
                                      <div className="flex items-center h-5">
                                        <input
                                          id="pollLocation"
                                          type="checkbox"
                                          checked={activeSyncParams.pollLocation}
                                          onChange={(ev) => setActiveSyncParams({ ...activeSyncParams, pollLocation: ev.target.checked })}
                                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 disabled:text-gray-500 border-gray-300 rounded"
                                        />
                                      </div>
                                      <div className="ml-3 text-sm">
                                        <label htmlFor="pollLocation" className="font-medium text-gray-700">
                                          Location (SY+LO)
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {activeSyncParams.pollStatus !== undefined && (
                                    <div className="relative flex items-start">
                                      <div className="flex items-center h-5">
                                        <input
                                          id="pollStatus"
                                          type="checkbox"
                                          checked={activeSyncParams.pollStatus}
                                          onChange={(ev) => setActiveSyncParams({ ...activeSyncParams, pollStatus: ev.target.checked })}
                                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 disabled:text-gray-500 border-gray-300 rounded"
                                        />
                                      </div>
                                      <div className="ml-3 text-sm">
                                        <label htmlFor="pollStatus" className="font-medium text-gray-700">
                                          Status (ER)
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {activeSyncParams.pollLogs !== undefined && (
                                    <div className="relative flex items-start">
                                      <div className="flex items-center h-5">
                                        <input
                                          id="pollLogs"
                                          type="checkbox"
                                          checked={activeSyncParams.pollLogs}
                                          onChange={(ev) => setActiveSyncParams({ ...activeSyncParams, pollLogs: ev.target.checked })}
                                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 disabled:text-gray-500 border-gray-300 rounded"
                                        />
                                      </div>
                                      <div className="ml-3 text-sm">
                                        <label htmlFor="pollLogs" className="font-medium text-gray-700">
                                          Logs (UP)
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </fieldset>
                            )}
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6">
                        <button
                          disabled={!_.values(activeSyncSelection).some((selected) => !!selected)}
                          onClick={() => synchronizeReceivers()}
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-200 sm:text-sm">
                          Synchronize
                        </button>
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={!!activeImport} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={(ev) => !importBusy && setActiveImport(null)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:max-w-4xl sm:w-full sm:p-6 overflow-hidden">
                  {!importBusy && (
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                      <button
                        type="button"
                        tabIndex={-1}
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => setActiveImport(null)}>
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  )}
                  {!!activeImport && !!importResults && !!importResults.results && (
                    <div>
                      <div className="mt-3 sm:mt-5">
                        <Dialog.Title as="h3" className="text-center text-lg leading-6 font-medium text-gray-900">
                          Import Logs: {activeImport.name}
                        </Dialog.Title>
                        <div className="mt-2 max-h-screen overflow-y-scroll">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                              <tr>
                                <th scope="col" className="px-2 py-2 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                  Filename
                                </th>
                                <th scope="col" className="w-20 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                  UP
                                </th>
                                <th scope="col" className="w-20 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                  ER
                                </th>
                                <th scope="col" className="w-20 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                  Ignores
                                </th>
                                <th scope="col" className="w-20 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                  Errors
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              {importResults.results.map((result, i) => {
                                const errors = result.errors ? result.errors.length : 0
                                const errorsTooltip = result.errors
                                  ? errors > 10
                                    ? result.errors.slice(0, 10).join('\n') + '\n...'
                                    : result.errors.join('\n')
                                  : undefined

                                return (
                                  <tr
                                    key={i}
                                    className={classNames({
                                      'bg-gray-50': i % 2 === 0,
                                      'text-gray-300': result.id && result.logs === 0 && result.statuses === 0 && errors === 0,
                                      'text-red-500': errors > 0,
                                    })}>
                                    <td className="whitespace-nowrap py-2 px-2 text-sm font-medium sm:pl-6">{result.filename}</td>
                                    <td className="whitespace-nowrap px-2 py-2 text-center text-sm">{result.id ? result.logs : '-'}</td>
                                    <td className="whitespace-nowrap px-2 py-2 text-center text-sm">{result.id ? result.statuses : '-'}</td>
                                    <td className="whitespace-nowrap px-2 py-2 text-center text-sm">{result.id ? result.ignored : '-'}</td>
                                    <td className="whitespace-nowrap px-2 py-2 text-center text-sm" title={errorsTooltip}>
                                      {result.errors ? result.errors.length : '-'}
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}
