import { useEffect, useLayoutEffect, useRef, useState, ChangeEvent, FormEvent } from 'react'

import useWebSocket, { ReadyState } from 'react-use-websocket'

import { useAppSelector, useAppDispatch } from '../store'
import { setStatus, addMessage, clearMessages, setCommand, clearCommand } from './slice'
import { notify } from '../notification/slice'

type SendJsonMessage = (jsonMessage: any, keep?: boolean) => void

type ShellProps = {
  receiverId: string
  readyState: ReadyState
  sendJsonMessage: SendJsonMessage
}

const CONNECTION_STATUSES = {
  [ReadyState.CONNECTING]: 'connecting',
  [ReadyState.OPEN]: 'connected',
  [ReadyState.CLOSING]: 'disconnecting',
  [ReadyState.CLOSED]: 'disconnected',
  [ReadyState.UNINSTANTIATED]: 'uninstantiated',
}

function Shell({ receiverId, readyState, sendJsonMessage }: ShellProps) {
  const dispatch = useAppDispatch()
  const [enableAutoScroll, setEnableAutoScroll] = useState(true)
  const historyEl = useRef<HTMLPreElement>(null)
  const commandInput = useRef<HTMLInputElement>(null)
  const status = useAppSelector((state) => state.shell.status[receiverId])
  const history = useAppSelector((state) => state.shell.history[receiverId])
  const currentCommand = useAppSelector((state) => state.shell.commands[receiverId]) || ''
  const connectionStatus = CONNECTION_STATUSES[readyState]
  const connecting = readyState === ReadyState.CONNECTING || status === 'connected'
  const connected = status === 'ready'

  async function onUploadCommands(ev: ChangeEvent<HTMLInputElement>) {
    const files = ev.target.files || []

    if (files.length === 0) {
      return
    }

    const file = files[0]
    const lines = (await file.text())
      .split(/\r\n|\n/)
      .map((line) => line.trim())
      .filter((line) => line.length > 0)

    lines.forEach((line) => sendJsonMessage({ ev: 'data', data: `${line}\r\n` }))
    ev.target.value = ''
  }

  function onSendCurrentCommand(ev: FormEvent) {
    ev.preventDefault()

    sendJsonMessage({ ev: 'data', data: `${currentCommand}\r\n` })
    dispatch(clearCommand({ receiverId }))
  }

  useLayoutEffect(() => {
    if (!enableAutoScroll || !historyEl.current) {
      return
    }

    const el = historyEl.current

    el.scrollTop = el.scrollHeight - el.clientHeight
  }, [enableAutoScroll, connected, history])

  useEffect(() => {
    if (commandInput.current) {
      commandInput.current.focus()
    }
  }, [connected])

  return (
    <form onSubmit={onSendCurrentCommand}>
      {connected && (
        <div className="mb-1 space-y-4">
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id="autoscroll"
                name="autoscroll"
                type="checkbox"
                checked={enableAutoScroll}
                onChange={(ev) => setEnableAutoScroll(ev.target.checked)}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="autoscroll" className="font-medium text-gray-700">
                Enable auto-scrolling
              </label>
            </div>
          </div>
        </div>
      )}
      {(connecting || connected) && (
        <pre
          ref={historyEl}
          style={{ maxHeight: '600px' }}
          className="w-full p-2 border border-gray-500 bg-gray-900 text-xs text-gray-300 rounded-t-md overflow-scroll">
          {(history || []).length === 0 && <span>(no history at this time)</span>}
          {(history || []).map((item, i) => {
            return <span key={i}>{item}</span>
          })}
          {connecting && "\n=====\nTrying to connect to this console..."}
        </pre>
      )}
      {connected && (<div className="relative border border-gray-200 rounded-b-md">
        <input
          ref={commandInput}
          id="command"
          name="command"
          type="text"
          className="p-2 pr-24 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-b-md"
          placeholder={connected ? 'Enter a command...' : `Receiver connection is: ${connectionStatus}`}
          disabled={!connected}
          value={currentCommand}
          onChange={(ev) => dispatch(setCommand({ receiverId, value: ev.target.value }))}
        />
        <div className="absolute inset-y-0 right-0 px-2 flex items-center pointer-events-none bg-gray-100 rounded-br-md">
          <span className="text-gray-400 text-xs">({status})</span>
        </div>
      </div>)}
      {connected && (
        <div className="mt-2 space-y-2">
          <div>or upload a command file (text):</div>
          <input type="file" onChange={onUploadCommands} />
        </div>
      )}
    </form>
  )
}

type ShellContainerProps = {
  receiverId: string
  onClose?: (user: boolean) => void
}

export default function ShellContainer({ receiverId, onClose }: ShellContainerProps) {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
  const endpoint = process.env.REACT_APP_HOSTNAME || window.location.host
  const url = `${protocol}//${endpoint}/api/ws/shell/${receiverId}`
  const dispatch = useAppDispatch()
  const [pingTask, setPingTask] = useState<number>()
  const { sendJsonMessage, readyState } = useWebSocket(url, {
    share: true,
    retryOnError: true,
    reconnectAttempts: 3,
    reconnectInterval: 5000,
    shouldReconnect: (ev) => true,
    onOpen: (ev) => {
      console.log('console connected', receiverId)
      dispatch(setStatus({ receiverId: receiverId, value: 'connected' }))
      if (pingTask) {
        clearInterval(pingTask)
      }
      setPingTask(setInterval(() => sendJsonMessage({ ev: 'ping' }), 10000) as any)
    },
    onMessage: (ev) => {
      const message = JSON.parse(ev.data)

      if (message.ev === 'ready') {
        console.log('console ready', receiverId)
        dispatch(setStatus({ receiverId, value: 'ready' }))
      } else if (message.ev === 'data') {
        dispatch(addMessage({ receiverId, message: message.data }))
      } else if (message.ev === 'error') {
        dispatch(notify({ task: 'console', type: 'error', title: 'Console Error', message: message.error }))
        dispatch(setStatus({ receiverId, value: 'error' }))
      } else {
        console.log('unknown message', message)
      }
    },
    onError: (ev) => {
      dispatch(setStatus({ receiverId, value: 'error' }))
      dispatch(notify({ task: 'console', type: 'error', title: 'Console Error', message: 'Socket closed with error' }))

      if (pingTask) {
        clearInterval(pingTask)
        setPingTask(undefined)
      }
    },
    onClose: (ev) => {
      console.log('console disconnected', receiverId)
      dispatch(setStatus({ receiverId, value: 'disconnected' }))

      if (pingTask) {
        clearInterval(pingTask)
        setPingTask(undefined)
      }

      if (onClose) {
        onClose(false)
      }
    },
  })

  return <Shell receiverId={receiverId} readyState={readyState} sendJsonMessage={sendJsonMessage} />
}
