import { gql } from '@apollo/client'

export type IReceiverLocalization = {
  localizedAt: string
  state: string
  beeper: boolean
  v: number
  aL: number
  aE: number
  shutdownSupercap: number
  sleepBattery: number
  chargePulse1: number
  chargePulse2: number
  chargePulse3: number
  chargePulse4: number
  noise1: number
  noise2: number
  noise3: number
  noise4: number
  tagsInArchive: number
  bluetooth: boolean
  localtime: string
  latitude: number
  longitude: number
  altitude: number
  hdop: number
  timeToValidGnssSignal: number
  satellites: number
}

export type IReceiverSchedule = {
  enabled: boolean
  pollLocation: boolean
  pollStatus: boolean
  pollLogs: boolean
  hour?: number | null
  minute?: number | null
  second?: number | null
}

export type IReceiver = {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  hostname: string
  port: number
  timeout: number
  debug: boolean
  enabled: boolean
  opened: boolean
  dead: boolean
  busy: boolean
  currentTask: string
  name: string
  location: string
  startPollingAt: string | null
  endPollingAt: string | null
  schedules: IReceiverSchedule[]
  device: string
  firmware: string
  serial: string
  lastPolled: IReceiverLocalization
  lastLocalized: IReceiverLocalization
}

export type IListReceivers = {
  receivers: IReceiver[]
}

export type IGetReceiver = {
  receiver: IReceiver
}

const receiverFields = `
  fragment receiverFields on Receiver {
    id
    createdAt
    updatedAt
    deletedAt
    hostname
    port
    timeout
    debug
    enabled
    opened
    dead
    busy
    name
    location
    startPollingAt
    endPollingAt
    schedules {
      enabled
      pollLocation
      pollStatus
      pollLogs
      hour
      minute
      second
    }
    device
    firmware
    serial
    lastPolled {
      localizedAt
      state
      beeper
      v
      aL
      aE
      shutdownSupercap
      sleepBattery
      chargePulse1
      chargePulse2
      chargePulse3
      chargePulse4
      noise1
      noise2
      noise3
      noise4
      tagsInArchive
      bluetooth
      localtime
      latitude
      longitude
      altitude
      hdop
      timeToValidGnssSignal
      satellites
    }
    lastLocalized {
      localizedAt
      state
      beeper
      v
      aL
      aE
      shutdownSupercap
      sleepBattery
      chargePulse1
      chargePulse2
      chargePulse3
      chargePulse4
      noise1
      noise2
      noise3
      noise4
      tagsInArchive
      bluetooth
      localtime
      latitude
      longitude
      altitude
      hdop
      timeToValidGnssSignal
      satellites
    }
    currentTask
  }
`

export const GET_RECEIVER = gql`
  query GetReceiver($receiverId: ID!) {
    receiver(receiverId: $receiverId) {
      ...receiverFields
    }
  }

  ${receiverFields}
`

export const LIST_RECEIVERS = gql`
  query ListReceivers {
    receivers {
      ...receiverFields
    }
  }

  ${receiverFields}
`

export const CREATE_RECEIVER = gql`
  mutation (
    $hostname: String!
    $port: Int!
    $timeout: Int!
    $debug: Boolean!
    $enabled: Boolean!
    $name: String
    $location: String
    $startPollingAt: DateTime
    $endPollingAt: DateTime
    $schedules: [ReceiverScheduleInput]
  ) {
    createReceiver(
      input: {
        hostname: $hostname
        port: $port
        timeout: $timeout
        debug: $debug
        enabled: $enabled
        name: $name
        location: $location
        startPollingAt: $startPollingAt
        endPollingAt: $endPollingAt
        schedules: $schedules
      }
    ) {
      ...receiverFields
    }
  }

  ${receiverFields}
`

export const UPDATE_RECEIVER = gql`
  mutation (
    $receiverId: ID!
    $hostname: String!
    $port: Int!
    $timeout: Int!
    $debug: Boolean!
    $enabled: Boolean!
    $name: String
    $location: String
    $startPollingAt: DateTime
    $endPollingAt: DateTime
    $schedules: [ReceiverScheduleInput]
  ) {
    updateReceiver(
      receiverId: $receiverId
      input: {
        hostname: $hostname
        port: $port
        timeout: $timeout
        debug: $debug
        enabled: $enabled
        name: $name
        location: $location
        startPollingAt: $startPollingAt
        endPollingAt: $endPollingAt
        schedules: $schedules
      }
    ) {
      ...receiverFields
    }
  }

  ${receiverFields}
`

export const DELETE_RECEIVER = gql`
  mutation ($receiverId: ID!) {
    deleteReceiver(receiverId: $receiverId) {
      ...receiverFields
    }
  }

  ${receiverFields}
`

export const RESTORE_RECEIVER = gql`
  mutation ($receiverId: ID!) {
    restoreReceiver(receiverId: $receiverId) {
      ...receiverFields
    }
  }

  ${receiverFields}
`
