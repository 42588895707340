import classNames from 'classnames'

import { NavLink } from 'react-router-dom'

// import { useQuery } from '@apollo/client'
// import { LIST_RECEIVERS, IListReceivers } from '../graphql'

import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/solid'

import { useAuth } from '../auth'

import { useAppSelector } from '../store'

export default function Menu() {
  const auth = useAuth()

  // const { data } = useQuery<IListReceivers>(LIST_RECEIVERS, {
  //   variables: {},
  // })
  const items = useAppSelector((state) => state.menu.items)

  // if (data) {
  //   items = items.concat(
  //     data.receivers
  //       .filter((receiver) => !receiver.deletedAt && receiver.enabled)
  //       .map((receiver) => {
  //         return {
  //           id: `receiver-${receiver.id}`,
  //           parent: 'receivers',
  //           to: `/receivers/${receiver.id}`,
  //           name: receiver.name,
  //         }
  //       })
  //   )
  // }

  const rootItems = items.filter((item) => !item.parent)
  const navigation = rootItems.map((item) => {
    const children = items.filter((other) => other.parent === item.id)

    return { ...item, children }
  })

  return (
    <div className="flex flex-col flex-grow border-r border-gray-200 pt-2 pb-2 bg-white overflow-y-auto">
      <div className="flex items-center justify-center flex-shrink-0 px-4 border-b">
        <NavLink to="/">
          <img className="mx-auto rounded-md" src={process.env.PUBLIC_URL + '/static/logo.png'} alt="Workflow" />
          <div className="my-2">ECQUA</div>
        </NavLink>
      </div>
      <div className="mt-5 flex-grow flex flex-col">
        <nav className="flex-1 px-2 space-y-1 bg-white" aria-label="Sidebar">
          {navigation.map((item) =>
            !item.children ? (
              <div key={item.id}>
                <NavLink
                  to={item.to}
                  className={({ isActive }) =>
                    classNames(
                      isActive ? 'bg-gray-100 text-gray-900' : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group w-full flex items-center pl-7 pr-2 py-2 text-sm font-medium rounded-md'
                    )
                  }>
                  {item.name}
                </NavLink>
              </div>
            ) : (
              <div key={item.id} className="space-y-1">
                <NavLink
                  to={item.to}
                  className={({ isActive }) =>
                    classNames(
                      isActive ? 'bg-gray-100 text-gray-900' : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group w-full flex items-center pr-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500'
                    )
                  }>
                  <svg
                    className={classNames(
                      'text-gray-400 rotate-90',
                      'mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                    )}
                    viewBox="0 0 20 20"
                    aria-hidden="true">
                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                  </svg>
                  {item.name}
                </NavLink>
                <div className="space-y-1">
                  {item.children.map((childItem) => (
                    <NavLink
                      key={childItem.id}
                      to={childItem.to}
                      className={({ isActive }) =>
                        classNames(
                          isActive ? 'text-gray-900' : 'text-gray-600',
                          'group w-full flex items-center pl-10 pr-2 py-2 text-sm transition-colors ease-in-out duration-150 font-medium rounded-md hover:text-gray-900 hover:bg-gray-50'
                        )
                      }>
                      {childItem.name}
                    </NavLink>
                  ))}
                </div>
              </div>
            )
          )}
        </nav>
      </div>
      <div className="flex-shrink-0 flex border-t border-gray-200 pt-2 px-2">
        <button
          title="Sign out"
          className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 w-full flex items-center justify-center px-2 py-2 text-base font-medium rounded-md"
          onClick={(ev) => auth.signout()}>
          <ArrowLeftOnRectangleIcon className="w-5 h-5" />
        </button>
      </div>
    </div>
  )
}
