import { createSlice } from '@reduxjs/toolkit'

interface INotification {
  task: string
  type: 'error' | 'info' | 'progress'
  title: string
  message?: string
}

interface INotificationState {
  items: INotification[]
}

export const notificationSlice = createSlice({
  name: 'menu',
  initialState: {
    items: [],
  } as INotificationState,
  reducers: {
    notify: (state, action) => {
      const item = { ...action.payload }
      const items = [...state.items.filter((other) => other.task !== item.task), item]

      return { ...state, items }
    },
    dismiss: (state, action) => {
      const items = state.items.filter((other) => other.task !== action.payload.task)

      return { ...state, items }
    },
  },
})
export const { notify, dismiss } = notificationSlice.actions
export default notificationSlice.reducer
