import { useContext, useEffect, useState, createContext, ReactNode, FormEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { LockClosedIcon } from '@heroicons/react/24/solid'

export interface IAuthContext {
  busy: boolean
  failed: boolean
  username?: string
  signin: (username: string, password: string) => Promise<void>
  signout: () => Promise<void>
}

type ILocationState = {
  from?: Location
}

const AuthContext = createContext<IAuthContext>(null!)
const EMPTY_STATE: ILocationState = {}

export function AuthProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state ? (location.state as ILocationState) : EMPTY_STATE
  const [busy, setBusy] = useState<boolean>(true)
  const [username, setUsername] = useState<string>()
  const [failed, setFailed] = useState<boolean>(false)

  useEffect(() => {
    async function check() {
      try {
        const response = await fetch('/api/logged', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'include',
        })
        const json = await response.json()

        return response.ok && json.success ? json.username : undefined
      } catch (e) {
        console.log(e)
        return undefined
      }
    }

    check()
      .then((username) => {
        if (username) {
          setUsername(username)
          if (locationState.from) {
            navigate(locationState.from || '/', { replace: true })
          }
        }
      })
      .finally(() => setBusy(false))
  }, [navigate, locationState])

  async function signin(username: string, password: string) {
    const data = new FormData()

    data.append('username', username)
    data.append('password', password)
    setBusy(true)
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        body: data,
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
      })
      const json = await response.json()

      if (response.ok && json.success) {
        setUsername(username)
        setFailed(false)
      } else {
        setFailed(true)
      }
    } catch (e) {
      console.log(e)
      setFailed(true)
    } finally {
      setBusy(false)
    }
  }

  async function signout() {
    setBusy(true)
    try {
      const response = await fetch('/api/logout', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
      })
      const json = await response.json()

      if (response.ok && json.success) {
        setUsername(undefined)
        setFailed(false)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setBusy(false)
    }
  }

  const value = { busy, failed, username, signin, signout }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return useContext(AuthContext)
}

function LoginForm() {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [busy, setBusy] = useState<boolean>(false)
  const auth = useAuth()

  async function onLogin(ev: FormEvent<HTMLFormElement>) {
    ev.preventDefault()
    setBusy(true)
    try {
      await auth.signin(username, password)
    } finally {
      setBusy(false)
    }
  }

  return (
    <div className="h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-12 w-auto" src={process.env.PUBLIC_URL + '/static/logo.png'} alt="Ecqua" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in</h2>
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST" onSubmit={onLogin}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">
                Username
              </label>
              <input
                id="username"
                name="username"
                type="email"
                autoComplete="username"
                value={username}
                onChange={(ev) => setUsername(ev.target.value)}
                disabled={busy}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Username"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
                disabled={busy}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
          </div>
          {auth.failed && (
            <div>
              <p className="text-red-500 text-sm">Error: invalid username or password.</p>
            </div>
          )}
          <div>
            <button
              type="submit"
              disabled={busy || username.length === 0 || password.length === 0}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 disabled:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 group-disabled:text-indigo-400" aria-hidden="true" />
              </span>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth()

  if (auth.busy) {
    return <div className="flex h-screen w-screen justify-center items-center text-gray-500 font-bold">Loading...</div>
  }
  if (!auth.username) {
    return <LoginForm />
  }
  return children
}
