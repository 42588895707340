import { createSlice } from '@reduxjs/toolkit'

interface IMenuItem {
  id: string
  parent?: string
  to: string
  name: string
}

interface IMenuState {
  items: IMenuItem[]
}

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    items: [
      // {
      //   id: 'receivers',
      //   to: '/receivers',
      //   name: 'Receivers',
      // },
    ],
  } as IMenuState,
  reducers: {
    addItem: (state, action) => {
      return { ...state, items: [...state.items, action.payload] }
    },
    removeItem: (state, action) => {
      return { ...state, items: state.items.filter((other) => other.id !== action.payload) }
    },
  },
})
export const { addItem, removeItem } = menuSlice.actions
export default menuSlice.reducer
